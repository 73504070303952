<!--
 * @Description: 套餐
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-24 22:51:35
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/staging/package.vue
-->
<template>
  <div class="app-wrapper">
    <div class="header-view">
      <span>产品服务及套餐</span>
    </div>
    <div class="table-view">
      <div class="table-row">
        <div class="table-cell">服务</div>
        <div class="table-cell" v-for="item in list" :key="item.id">
          <div class="cell-classify">
            <div class="classify-title">{{ item.title }}</div>
            <div class="classify-price">
              <span
                >￥<span>{{ item.price_yuan }}</span></span
              >/{{ item.cycle_desc }}
            </div>
            <div
              class="classify-btn"
              @click="
                () => {
                  sign = {
                    show: true,
                    data: {
                      ...item,
                    },
                  };
                }
              "
            >
              点击购买
            </div>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">生成视频条数（个）</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          {{ Number(item.video_num).toLocaleString() }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">绑定账号数（个）</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          {{ item.all_account }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">关键词拓词数量</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          {{ item.seo_keyword_num }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">价格</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          {{ item.price_yuan }}/{{ item.cycle_desc }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">营销工具挂载</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          <div class="check" v-if="`${item.marketing_tool}` === '2'">
            <img src="../../../assets/icon/check.png" alt="" />
          </div>
          <span v-else>—</span>
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">蓝V账号认证辅助</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          <div class="check" v-if="`${item.v_account_auth}` === '2'">
            <img src="../../../assets/icon/check.png" alt="" />
          </div>
          <span v-else>—</span>
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">员工账号认证辅助</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          <div class="check" v-if="`${item.staff_account_auth}` === '2'">
            <img src="../../../assets/icon/check.png" alt="" />
          </div>
          <span v-else>—</span>
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">蓝V账号装修</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          <div class="check" v-if="`${item.v_account_renovation}` === '2'">
            <img src="../../../assets/icon/check.png" alt="" />
          </div>
          <span v-else>—</span>
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">数据监测后台</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          <div class="check" v-if="`${item.data_monitoring_backend}` === '2'">
            <img src="../../../assets/icon/check.png" alt="" />
          </div>
          <span v-else>—</span>
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">AIGC工具培训</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          <div class="check" v-if="`${item.aigc_tool}` === '2'">
            <img src="../../../assets/icon/check.png" alt="" />
          </div>
          <span v-else>—</span>
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">抖音SEO培训</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          <div class="check" v-if="`${item.tiktok_seo}` === '2'">
            <img src="../../../assets/icon/check.png" alt="" />
          </div>
          <span v-else>—</span>
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell">MARSIGHTAI-ENGINE升级</div>
        <div class="table-cell center" v-for="item in list" :key="item.id">
          <div class="check" v-if="`${item.ai_engine_upgrade}` === '2'">
            <img src="../../../assets/icon/check.png" alt="" />
          </div>
          <span v-else>—</span>
        </div>
      </div>
    </div>
    <div class="introduction">
      <div>1、绑定账号数：即最大支持可绑定的抖音账号数量</div>
      <div>
        2、生产视频条数：即最大可生产数，根据客户所准备的账号时间、数量相关
      </div>
      <div>
        3、员工账号：并非指员工个人账号，是抖音基于企业蓝V账号下的一种账号类型；高级版及以上会赠送员工账号发布，企业可选择是否使用。
      </div>
      <div>
        4、MARSIGHTAI-ENGINE：是我方基于AIGC能力自主研发的基于内容（包含但不限于短视频、图文、文本等）生产、分发及关键词拓展的系统矩阵。
      </div>
    </div>

    <payment-view v-if="paymentState" :show.sync="paymentState"></payment-view>

    <!-- 签署合同 -->
    <sign-view
      v-if="sign.show"
      :show.sync="sign.show"
      v-bind="sign"
    ></sign-view>
  </div>
</template>

<script>
import PaymentView from "@/components/payment";
import SignView from "@/components/sign";
import { HTTP } from "../../../utils/request";

const URL = {
  list: "api/common/productList",
};

export default {
  components: {
    PaymentView,
    SignView,
  },
  data() {
    return {
      paymentState: false,
      sign: {
        show: false,
        title: "",
      },
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
      });
      this.list = res || [];
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-view {
  width: 100%;
  height: 65px;
  span {
    font-size: 20px;
    font-weight: normal;
    color: #2f3356;
    line-height: 65px;
    padding-left: 26px;
  }
}
.table-view {
  border-top: 1px solid #e4e8fc;
  .table-row {
    width: 100%;
    border-bottom: 1px solid #e4e8fc;
    display: flex;
    .table-cell {
      flex: 1;
      padding: 18px;
      border-right: 1px solid #e4e8fc;
      &:last-child {
        border-right: 0;
      }

      &:nth-child(1) {
        flex: 0.7;
      }
      font-size: 16px;
      color: #4e4e4e;
      display: flex;
      align-items: center;
      .check {
        width: 25px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    .center {
      justify-content: center;
    }
  }
}
.cell-classify {
  width: 100%;
  text-align: center;
  .classify-title {
    font-size: 26px;
    font-family: SourceHanSansCN-Normal-, SourceHanSansCN-Normal;
    font-weight: normal;
    color: #000000;
    margin-top: 40px;
  }
  .classify-price {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
    font-weight: normal;
    color: #4e4e4e;
    margin-top: 30px;
    & > span {
      font-size: 20px;
      color: #4e6bef;
      font-weight: bold;
      span {
        font-size: 30px;
      }
    }
  }
  .classify-btn {
    width: 168px;
    height: 37px;
    background-color: #4e6bef;
    font-size: 16px;
    color: #ffffff;
    margin: auto {
      top: 34px;
      bottom: 40px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
  }
}
.introduction {
  padding: 18px;
  font-size: 16px;
  line-height: 28px;
  color: #999999;
}
</style>
