<!--
 * @Description: 工作台
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-14 19:51:34
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/staging/index.vue
-->
<template>
  <div>
    <PackageView v-if="pageType === 'package'" />
    <HomeView v-else-if="pageType === 'home'" />
  </div>
</template>

<script>
import { HTTP } from "../../../utils/request";
import PackageView from "./package.vue";
import HomeView from "./home.vue";

const URL = {
  info: "api/customer/user/info",
};

export default {
  components: {
    PackageView,
    HomeView,
  },
  data() {
    return {
      pageType: "",
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const res = await HTTP({
        url: URL.info,
        method: "get",
      });
      if (res.needBuyProduct === "yes") {
        this.pageType = "package";
      } else if (res.needBuyProduct === "no") {
        this.pageType = "home";
      }
    },
  },
};
</script>
