<!--
 * @Description: 工作台（已登录）
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-14 22:43:00
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/staging/home.vue
-->
<template>
  <div>
    <div class="home-head app-wrapper">
      <div class="wrapper-title">数据概览</div>
      <div class="head-list">
        <div class="head-item">
          <div class="item-content">
            <div class="item-count">
              {{ statisticsInfo?.accountCount || "0" }}
            </div>
            <div class="item-name">账号总数</div>
            <div class="item-text">截止到今天的数据加总</div>
          </div>
          <div class="item-x"></div>
        </div>
        <div class="head-item">
          <div class="item-content">
            <div class="item-count">
              {{ statisticsInfo?.videoItemCount.count || "0" }}
            </div>
            <div class="item-name">视频总数</div>
          </div>
          <div class="item-x"></div>
        </div>
        <div class="head-item">
          <div class="item-content">
            <div class="item-count">
              {{ statisticsInfo?.videoPlayCount.count || "0" }}
            </div>
            <div class="item-name">视频播放量</div>
          </div>
          <!-- <div class="item-x"></div> -->
        </div>
        <!-- <div class="head-item">
          <div class="item-content">
            <div class="item-count">
              {{ statisticsInfo?.videoCount.download_count || "0" }}
            </div>
            <div class="item-name">视频下载数</div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="cooperate-view app-wrapper">
      <div class="wrapper-title">合作流程</div>
      <div class="cooperate-content">
        <div class="content-left">客 服 全 程 陪 跑</div>
        <div class="content-right">
          <img src="../../../assets/icon/lc.png" alt="" />
        </div>
      </div>
    </div>
    <div class="video-view app-wrapper">
      <div class="wrapper-title">视频模板示例</div>
      <div class="video-list">
        <div
          class="video-item"
          v-for="(item, index) in videoExampleList"
          :key="index"
        >
          <div class="video-item-bg" @click="handleVideoChange(item)">
            <img :src="item.cover" alt="" />
            <div class="content-img">
              <img src="../../../assets/icon/play.png" alt="" srcset="" />
            </div>
          </div>
          <div class="video-item-cells">
            <div class="cell">
              <div class="cell-label">适用行业</div>
              <div>：</div>
              <div class="cell-value">{{ item.industry }}</div>
            </div>
            <div class="cell">
              <div class="cell-label">视频属性</div>
              <div>：</div>
              <div class="cell-value">{{ item.attribute }}</div>
            </div>
            <!-- <div class="cell">
              <div class="cell-label">版本</div>
              <div>：</div>
              <div class="cell-value">iOS系统</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="problem-view app-wrapper">
      <div class="wrapper-title">常见问题</div>
      <div class="problem-list">
        <div class="problem-item">
          <div class="item-cell">
            <div class="cell-label cell-label2">Q</div>
            <div class="cell-text cell-text2">
              视频制作之前，需要准备哪些工作？
            </div>
          </div>
          <div class="item-cell">
            <div class="cell-label">A</div>
            <div class="cell-text">
              您需要完成以下准备工作，才可以进入视频制作阶段 <br />
              1、准备蓝V账号矩阵 <br />
              2、提供关键素材，包含：核心词、负面词、图片及视频 <br />
              3、完成蓝V账号绑定操作
            </div>
          </div>
        </div>
        <div class="problem-item">
          <div class="item-cell">
            <div class="cell-label cell-label2">Q</div>
            <div class="cell-text cell-text2">
              套餐中的视频发布数量是指什么？
            </div>
          </div>
          <div class="item-cell">
            <div class="cell-label">A</div>
            <div class="cell-text">
              套餐中的视频发布数量表示我们平台最多支持的视频发布数。这一数量会受到您
              拥有的账号数量和您进行账号矩阵准备的时间所影响，因此建议您尽快完成账号
              矩阵的准备，以充分利用套餐中的发布数量。
            </div>
          </div>
        </div>
        <div class="problem-item">
          <div class="item-cell">
            <div class="cell-label cell-label2">Q</div>
            <div class="cell-text cell-text2">套餐中的账号数量是指什么？</div>
          </div>
          <div class="item-cell">
            <div class="cell-label">A</div>
            <div class="cell-text">
              套餐中的账号数量表示我们平台最多支持的账号绑定数量。您可以根据
              自己的需求建立合适数量的账号矩阵，但需要注意的是，这个数量会影
              响您总的视频发布数。因此，为了充分利用套餐中的资源，请确保您合
              理规划和管理您的账号矩阵。
            </div>
          </div>
        </div>
        <div class="problem-item">
          <div class="item-cell">
            <div class="cell-label cell-label2">Q</div>
            <div class="cell-text cell-text2">
              抖音企业号认证之后昵称可以修改吗?
            </div>
          </div>
          <div class="item-cell">
            <div class="cell-label">A</div>
            <div class="cell-text">
              昵称应为基于公司或品牌名或产品的全称或与提供的其他有效材料具备
              关联性；不得以个人化昵称认证企业帐号，如XX公司董事长、XX公司
              CEO、XX小编等；昵称涉及引用名人姓名但无名人相关授权的无法通过
              审核。
            </div>
          </div>
        </div>
      </div>
      <div class="problem-mask" @click="goPages('Common')">查看更多</div>
    </div>
    <VideoView v-if="video.show" :show.sync="video.show" v-bind="video" />
  </div>
</template>

<script>
import { HTTP } from "../../../utils/request";
import VideoView from "@/pages/views/custom/examine/components/video.vue";

const URL = {
  statistics: "api/customer/userDashboard/statistics",
  videoExample: "api/common/config/videoExample",
};

export default {
  components: {
    VideoView,
  },
  data() {
    return {
      statisticsInfo: null,
      videoExampleList: [],
      video: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getStatistics();
    this.getVideoExample();
  },
  methods: {
    async getStatistics() {
      const res = await HTTP({
        url: URL.statistics,
        method: "get",
      });
      this.statisticsInfo = res;
    },
    async getVideoExample() {
      const res = await HTTP({
        url: URL.videoExample,
        method: "get",
      });
      this.videoExampleList = res || [];
    },
    handleVideoChange(item) {
      this.video = {
        show: true,
        data: {
          content: item.url,
        },
      };
    },
    goPages(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-title {
  font-size: 20px;
  color: #2f3356;
  font-weight: bold;
}
.home-head {
  padding: 16px 26px;
  .head-list {
    display: flex;
    margin-top: 16px;
    .head-item {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .item-content {
        .item-count {
          font-size: 28px;
          font-family: OPPOSans-B-, OPPOSans-B;
          font-weight: bold;
          color: #4e6bef;
        }
        .item-name {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: normal;
          color: #4e4e4e;
          margin-top: 4px;
        }
        .item-text {
          font-size: 12px;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: normal;
          color: #949494;
          margin-top: 4px;
        }
      }
      .item-x {
        width: 1px;
        height: 67px;
        background: #d5d7e0;
        margin-right: 35px;
      }
    }
  }
}
.cooperate-view {
  padding: 16px 26px;
  margin-top: 17px;
  .cooperate-content {
    display: flex;
    margin-top: 22px;
    .content-left {
      width: 22px;
      height: 157px;
      font-size: 22px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #5c6693;
      line-height: 25px;
      letter-spacing: 100px;
      border-right: 1px dashed #d5d7e0;
      padding-right: 42px;
      margin-right: 32px;
    }
    .content-right {
      width: 1089px;
      height: 148px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.video-view {
  padding: 16px 26px;
  margin-top: 17px;
  .video-list {
    display: flex;
    .video-item {
      flex: 1;
      display: flex;
      margin-top: 24px;
      &-bg {
        width: 70px;
        height: 114px;
        // background: #bfbfbf;
        margin-right: 22px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .content-img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba($color: #000000, $alpha: 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
        }
      }
      &-cells {
        flex: 1;
        .cell {
          display: flex;
          color: #7c7f97;
          font-size: 14px;
          margin-bottom: 8px;
          .cell-label {
            width: 60px;
            text-align: justify;
            text-align-last: justify;
          }
          .cell-value {
            flex: 1;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
            font-weight: normal;
            color: #4e4e4e;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.problem-view {
  padding: 16px 26px;
  margin-top: 17px;
  .problem-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .problem-item {
      width: 50%;
      margin-bottom: 30px;
      .item-cell {
        display: flex;
        margin-bottom: 14px;
        .cell-label {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #dbe1fb;
          font-size: 12px;
          font-weight: normal;
          color: #606060;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
        }
        .cell-text {
          flex: 1;
          margin-right: 20px;
          line-height: 24px;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: normal;

          font-size: 14px;
          color: #7c7f97;
        }
        .cell-label2 {
          background-color: #4e6bef;
          color: #fff;
        }
        .cell-text2 {
          color: #4b5eb0;
          font-size: 16px;
        }
      }
    }
  }
  .problem-mask {
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
    font-weight: normal;
    color: #4e6bef;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
</style>
